export const environment = {
  production: false,
  appName: 'Zhelezyaka',
  domain: 'localhost:4200',
  apiDomain: 'https://dev.permit.vega-tech.ru/api/api',
  mainDomain: 'https://dev.permit.vega-tech.ru',
  firebase: {
    apiKey: "AIzaSyCZDxNsW-PiQqvvTtJWNABuYDtUaIsSQis",
    authDomain: "zhelezyka-219e2.firebaseapp.com",
    projectId: "zhelezyka-219e2",
    storageBucket: "zhelezyka-219e2.appspot.com",
    messagingSenderId: "567684443963",
    appId: "1:567684443963:web:880b3a555cef3c4826d11e"
  }
};

